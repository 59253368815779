<template lang="pug">
div
  re-stories

  v-container.mt-4.mt-md-10.mb-3
    .d-md-flex.pb-md-4
      v-sheet.px-4.pt-5.pa-md-9.col-md-6.px-md-8.py-md-9.re-cursor-pointer(:class="shiftClasses" width="100%" @click="toJournal(false)")
        template(v-if="this.cashRegister.shiftOpen")
          .d-flex.justify-space-between.mb-2.mb-md-7
            p.text-body-1.text-md-h4 {{ `${$t("текущая_смена")} - №${shiftNumber}` }}
            p.text-body-1.text-md-h4.tertiary--text {{ shiftStartDateTime | moment("HH:mm - DD.MM.YYYY") }}
          .d-flex.justify-space-between.align-center
            p.text-h2.mb-0.text-md-h2.text-lg-h1.re-number-font {{ shiftRevenue | numeral('0,0.[00]') }} ₸
            v-chip.py-md-2.px-md-4.d-table.py-1(x-small color="reBackground lighten-2")
              p.text-subtitle-2.mb-0.text-md-h4 {{ `${shiftTicketNumber} ${numWord(shiftTicketNumber)}` }}
          template(v-if="this.cashRegister.shiftExpired")
            v-btn.mt-4.mb-2.mt-md-8.d-flex.mx-auto.text-subtitle-2.text-md-subtitle-1.rounded-lg(color="reBackground lighten-2" height="40" width="100%" @click.stop="toJournal(true)")
              v-icon.mr-3(left size="20" color="red") mdi-alert-circle
              | {{ $t("необходимо_закрыть_смену") }}
        template(v-else)
          p.text-subtitle-2.text-center.text-md-h3 {{ $t("нет_открытой_смены") }}
          v-btn.mt-4.mb-2.mt-md-8.d-flex.mx-auto.text-subtitle-2.text-md-subtitle-1.rounded-lg(height="40" width="100%" color="orange" @click.stop="$router.push('/kkm')") {{ $t("открыть_новую_смену") }}

      v-divider.d-md-none
      v-divider.d-none.d-md-block(vertical style="border-width: 1.5px;")

      .d-flex.col-6.pa-0.align-md-stretch(style="max-width: 49.89%;")

        v-btn.col-md-6.py-3.px-4(v-if="uPlusMiniAppEnabled" height="100%" :class="shiftFristBtnClasses" @click="uPlusMiniAppVisible = true; analyticsLogEvent({ eventName: 're_inventory_clicked_menu', eventProperties: { section_source: 'from_re_main_page' } })")
          .d-flex.flex-md-column.justify-center.align-center.flex-row
            v-icon.mr-1.mb-md-3(:size="btnIconSize") mdi-file-phone
            p.text-subtitle-2.mb-0.text-md-h4 {{ $t("учёт_плюс") }}

        v-btn.col-md-6.py-3.px-4(v-else height="100%" :class="shiftFristBtnClasses" @click="$router.push('/kkm/positions'); analyticsLogEvent({ eventName: 're_dash_tile_clicked', eventProperties: { tile_name: 'priceList' } })")
          .d-flex.flex-md-column.justify-center.align-center.flex-row
            v-icon.mr-1.mb-md-3(:size="btnIconSize") mdi-file-phone
            p.text-subtitle-2.mb-0.text-md-h4 {{ $t("прайс_лист") }}

        v-divider.d-md-none(vertical)
        v-divider.d-none.d-md-block(vertical style="border-width: 1.5px;")

        v-btn.col-md-6.py-3.px-4(v-if="nAMiniAppEnabled" height="100%" :class="shiftSecondBtnClasses" @click="nAMiniAppVisible = true")
          .d-flex.flex-md-column.justify-center.align-center.flex-row
            v-icon.mr-1.mb-md-3(:size="btnIconSize") mdi-percent-outline
            p.text-subtitle-2.mb-0.text-md-h4 {{ $t("налоги") }}

        v-btn.col-md-6.py-3.px-4(v-else height="100%" :class="shiftSecondBtnClasses" @click="$openLink(`https://link.rekassa.kz/help-rekassa-${locale}`, '_system'); analyticsLogEvent({ eventName: 're_dash_tile_clicked', eventProperties: { tile_name: 'manuals' } })")
          .d-flex.flex-md-column.justify-center.align-center.flex-row
            v-icon.mr-1.mb-md-3(:size="btnIconSize") mdi-microsoft-onenote
            p.text-subtitle-2.mb-0.text-md-h4 {{ $t("справочник") }}

    .mt-7.mt-md-10.pb-md-4
      router-link.text-h6.text-decoration-none.font-weight-bold.text-md-h2.mb-6.re-link(to="/services") {{ $t("сервисы") }}
        v-icon.ml-1(:large="isLarge") mdi-chevron-right
      div.mt-3
        div.slide-group__wrapper#services-slider
          div.slide-group__content
            div(v-for="(service, index) in services" :key="index")
              v-card.mr-2.pa-3.rounded-md.pa-md-6.mr-md-4(:width="serviceCardWidth" :height="serviceCardHeight" rounded="xl" outlined @click="toService(service.location, service.tileName, false)")
                v-img.mb-4.mb-md-6(eager :width="iconSize" :height="iconSize" :src="`/static/${service.icon}`")
                p.font-weight-bold.custom-title {{ service.title }}
                p.pa-0.custom-subtitle.mt-2 {{ service.subtitle }}

    .mt-7.mt-md-10.pb-md-4
      router-link.text-h6.text-decoration-none.font-weight-bold.text-md-h2.mb-6.re-link(to="/marketplaces") {{ $t("маркетплейсы") }}
        v-icon.ml-1(:large="isLarge") mdi-chevron-right
      div.mt-3
        div.slide-group__wrapper
          div.slide-group__content
            div(v-for="(marketplace, index) in marketplaces" :key="index")
              v-card.mr-2.pa-3.rounded-md.pa-md-5.mr-md-4(rounded="xl" :width="marketCardWidth" :height="marketCardHeight" outlined @click="toService(marketplace.location, marketplace.tileName, true)")
                .d-flex.justify-space-between.mb-2.mb-md-4.align-center
                  p.font-weight-bold.mr-2.mr-md-5.mb-0.mt-1.custom-title {{ marketplace.title }}
                  v-img.flex-grow-0(eager :width="iconSize" :height="iconSize" :src="`/static/${marketplace.icon}`")
                p.pa-0.mt-4.custom-subtitle {{ marketplace.subtitle }}

    .mt-7.mt-md-10.pb-md-4(v-if="showPromotions")
      .text-h6.text-decoration-none.font-weight-bold.text-md-h2.mb-3 {{ $t("акции") }}
      re-promotions

  re-mini-app(v-model="uPlusMiniAppVisible" :url="uPlusMiniAppUrl" :params="uPlusMiniAppParams" :name="uPlusMiniAppName")

  re-mini-app(v-if="nAMiniAppEnabled"  v-model="nAMiniAppVisible" :url="nAMiniAppUrl" :name="nAMiniAppName")

</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getLocale } from '../i18n/index'
import helpUrls from '../utils/helpUrls'
import billsAndCoinsMixin from '../mixins/billsAndCoinsMixin'
import Stories from './utils/Stories.vue'
import Promotions from './utils/Promotions.vue'
import MiniApp from './utils/MiniApp.vue'

export default {
  components: {
    're-stories': Stories,
    're-promotions': Promotions,
    're-mini-app': MiniApp,
  },

  mixins: [billsAndCoinsMixin],

  data: (vm) => ({
    showPromotions: false,

    uPlusMiniAppEnabled: false,
    uPlusMiniAppVisible: false,
    uPlusMiniAppUrl: process.env.VUE_APP_MINIAPP_UPLUS_URL,
    uPlusMiniAppName: process.env.VUE_APP_MINIAPP_UPLUS_NAME,
    uPlusMiniAppParams: [
      { viewType: 'home' },
    ],

    nAMiniAppEnabled: false,
    nAMiniAppVisible: false,
    nAMiniAppUrl: null,
    nAMiniAppName: null,

    slider: null,
    isDown: false,
    startX: null,
    scrollLeft: null,
    velX: 0,
    momentumID: null,

    services: [
      {
        title: 'Freedom Box',
        subtitle: vm.$t('терминал_для_платежей_и_продажи_товаров_в_рассрочку'),
        icon: 'freedom-box-services.png',
        location: '/kkm/freedom/box',
        tileName: 'freedomBox',
      },
      {
        title: 'Freedom Credit',
        subtitle: vm.$t('онлайн_займы_до_млн_тенге_на_карту'),
        icon: 'freedom-credit-services.png',
        location: '/kkm/freedom/credit',
        tileName: 'freedomCredit',
      },
      {
        title: 'Halyk Pos',
        subtitle: vm.$t('прием_платежей_картами_с_помощью_телефона'),
        icon: 'halyk-pos-services.png',
        location: '/kkm/halyk/pos',
        tileName: 'halykPos',
      },
      {
        title: vm.$t('ТИС'),
        subtitle: vm.$t('поможет_увеличить_порог_по_ндс'),
        icon: 'tcis-services.png',
        location: '/kkm/uchet/tcis',
        tileName: 'tcis',
      },
    ],

    marketplaces: [
      {
        title: 'Каспи Магазин',
        subtitle: vm.$t('фискализация_продаж_прямо_из_маркетплейса'),
        icon: 'kaspi-shop-services.png',
        location: '/kkm/kaspi/shop',
        tileName: 'kaspiShop',
      },
      {
        title: vm.$t('подайте_заявку'),
        subtitle: vm.$t('подключи_свой_маркетплейс'),
        icon: 'marketplace-shop-services.png',
        location: '/kkm/marketplace-request',
        tileName: 'marketplaceRequest',
      },
    ],
  }),

  beforeRouteEnter(to, from, next) {
    // Если FreedomBox или Мой учет iframe то перенаправляем на /kkm
    if (process.env.VUE_APP_FFB_POS_MODE === 'true' || window.location !== window.parent.location) {
      next('/kkm')
    } else {
      next()
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
    }),

    locale() {
      return getLocale()
    },

    shift() {
      return this.cashRegister ? this.cashRegister.shift : null
    },

    shiftNumber() {
      if (this.shift && this.shift.open && this.shift.shiftNumber) {
        return this.shift.shiftNumber
      }
      return null
    },

    shiftRevenue() {
      if (this.shift && this.shift.open && this.shift.data.revenue) {
        return this.getNumberFromBillsAndCoins(this.shift.data.revenue.sum) * (this.shift.data.revenue.isNegative ? -1 : 1)
      }
      return null
    },

    shiftTicketNumber() {
      if (this.cashRegister && this.shift && this.shift.open && this.shift.shiftTicketNumber > 0) {
        return this.shift.shiftTicketNumber
      }
      return 0
    },

    shiftStartDateTime() {
      if (this.shift && this.shift.openTime) {
        return this.shift.openTime
      }
      return null
    },

    isLarge() {
      return this.$vuetify.breakpoint.mdAndUp
    },

    btnIconSize() {
      return this.isLarge ? '48px' : '20px'
    },

    iconSize() {
      return this.isLarge ? '72px' : '40px'
    },

    serviceCardWidth() {
      return this.isLarge ? '288px' : '160px'
    },

    serviceCardHeight() {
      return this.isLarge ? '264px' : '160px'
    },

    marketCardWidth() {
      return this.isLarge ? '282px' : '180px'
    },

    marketCardHeight() {
      return this.isLarge ? '212px' : '140px'
    },

    shiftClasses() {
      return this.isLarge ? 'rounded-tl-xl rounded-bl-xl' : 'rounded-t-xl'
    },

    shiftFristBtnClasses() {
      return this.isLarge ? 'rounded-0' : 'rounded-0 rounded-bl-xl'
    },

    shiftSecondBtnClasses() {
      return this.isLarge ? 'rounded-r-xl rounded-l-0' : 'rounded-0 rounded-br-xl'
    },
  },

  watch: {
    $route(to) {
      if (to.fullPath === '/list') {
        this.showCashRegisterSwitch(true)
        this.$router.push('/')
      }
    },
  },

  mounted() {
    this.checkUPlusAccess()
    this.checkNAAccess()

    if (this.$route.path === '/list') {
      setTimeout(() => {
        this.showCashRegisterSwitch(true)
      }, 1000)
      this.$router.push('/')
    }

    this.mouseScrollInit()

    this.analyticsLogEvent({ eventName: 're_dash_opened' })
  },

  methods: {
    ...mapActions({
      firebaseRemoteConfigGetString: 'tools/firebaseRemoteConfigGetString',
      showCashRegisterSwitch: 'tools/showCashRegisterSwitch',
      showOfdRegIframe: 'tools/showOfdRegIframe',
      showConfirm: 'tools/showConfirm',
      analyticsLogEvent: 'analytics/logEvent',
      showSnackbar: 'tools/showSnackbar',
    }),

    async checkUPlusAccess() {
      const allowedPhoneNumbers = await this.firebaseRemoteConfigGetString('UPLUS_ALLOWED_PHONE_NUMBERS')
      const normalizedUserPhoneNumber = this.user.phone.replace(/[^0-9]/g, '')

      if (allowedPhoneNumbers && allowedPhoneNumbers.indexOf(normalizedUserPhoneNumber) !== -1) {
        this.uPlusMiniAppEnabled = true
      }
    },

    checkNAAccess() {
      this.firebaseRemoteConfigGetString('NA_CONFIG').then((result) => {
        try {
          const config = JSON.parse(result)
          this.nAMiniAppEnabled = config.enabled
          this.nAMiniAppUrl = config.url
          this.nAMiniAppName = config.name
        } catch (error) {
          console.error('Error parsing NA_CONFIG')
        }
      })
    },

    toJournal(zxreport) {
      if (this.shiftNumber) {
        this.$router.push(`/shifts/${this.cashRegister.id}/${this.shiftNumber}${zxreport ? '/zxreport' : ''}`)
      }
    },

    toService(location, tileName, byPass) {
      const servicesSlider = document.querySelector('#services-slider')
      if (byPass || servicesSlider.getAttribute('data-slide-dragging') === 'false') {
        this.$router.push(location)
        this.analyticsLogEvent({ eventName: 're_dash_tile_clicked', eventProperties: { tile_name: tileName } })
      }
    },

    showError() {
      this.showConfirm({
        title: this.$t('ошибка'),
        text: this.cashRegister.data.fdo.result.text,
        rejectText: this.$t('подробнее'),
      }).then(() => {}).catch(() => {
        this.$openLink(helpUrls[this.cashRegister.data.fdo.result.code])
      })
    },

    numWord(value) {
      const words = this.locale === 'ru' ? ['чек', 'чека', 'чеков'] : ['чек', 'чек', 'чек']
      value = Math.abs(value) % 100
      const num = value % 10
      if (value > 10 && value < 20) return words[2]
      if (num > 1 && num < 5) return words[1]
      if (num === 1) return words[0]
      return words[2]
    },

    mouseScrollInit() {
      this.slider = document.querySelector('#services-slider')

      this.slider.addEventListener('mousedown', (e) => {
        this.isDown = true
        this.slider.setAttribute('data-slide-dragging', 'false')
        this.startX = e.pageX - this.slider.offsetLeft
        this.scrollLeft = this.slider.scrollLeft
        this.cancelMomentumTracking()
      })

      this.slider.addEventListener('mouseleave', () => {
        this.isDown = false
      })

      this.slider.addEventListener('mouseup', () => {
        this.isDown = false
        this.beginMomentumTracking()
      })

      this.slider.addEventListener('mousemove', (e) => {
        if (!this.isDown) return
        e.preventDefault()
        const x = e.pageX - this.slider.offsetLeft
        const walk = (x - this.startX)
        if (walk !== 0) this.slider.setAttribute('data-slide-dragging', 'true')
        const prevScrollLeft = this.slider.scrollLeft
        this.slider.scrollLeft = this.scrollLeft - walk
        this.velX = this.slider.scrollLeft - prevScrollLeft
      })

      this.slider.addEventListener('wheel', () => {
        this.cancelMomentumTracking()
      })
    },

    beginMomentumTracking() {
      this.cancelMomentumTracking()
      this.momentumID = requestAnimationFrame(this.momentumLoop)
    },

    cancelMomentumTracking() {
      cancelAnimationFrame(this.momentumID)
    },

    momentumLoop() {
      this.slider.scrollLeft += this.velX
      this.velX *= 0.95
      if (Math.abs(this.velX) > 0.5) {
        this.momentumID = requestAnimationFrame(this.momentumLoop)
      }
    },
  },
}
</script>
<style scoped lang="stylus">
.custom-title
  font-size 16px
  line-height 18px

  @media screen and (min-width 960px)
    font-size 22px
    line-height 26px

.custom-subtitle
  font-size 13px
  line-height 16px

  @media screen and (min-width 960px)
    font-size 16px
    line-height 22px

.v-divider--vertical
  margin 0

.slide-group__wrapper {
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  contain: content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.slide-group__wrapper::-webkit-scrollbar {
  display: none;
  width: 0px;
  background: transparent;
}

.slide-group__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  position: relative;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
}

.mdi-alert-circle::before {
  background-color: white;
  border-radius: 15px;
}
</style>
